import React, { useState, useEffect } from "react"

import "react-toastify/dist/ReactToastify.css"

import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import { ptBR } from "@mui/material/locale"

import { createTheme, ThemeProvider } from "@mui/material/styles"

import { CssBaseline, useMediaQuery } from "@mui/material"
import ColorModeContext from "./layout/themeContext"

import Routes from "./routes"

import config from "./config/config.json"
import "./index.css"

const queryClient = new QueryClient()
const palette = config.palette

const App = () => {
    const [locale, setLocale] = useState()

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
    const preferredTheme = window.localStorage.getItem("preferredTheme")
    const [mode, setMode] = useState(
        preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light",
    )

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"))
            },
        }),
        [],
    )

    useEffect(() => {
        document.title = config.system.name
    }, [])

    const theme = createTheme(
        {
            typography: {
                fontFamily: config.system.font.join(","),
            },
            overrides: {
                MuiPaper: {
                    root: {
                        backgroundColor: palette.paperBackground[mode],
                    },
                },
            },
            scrollbarStyles: {
                "&::-webkit-scrollbar": {
                    width: "4px",
                    height: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                    boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
                    backgroundColor: palette.scrollbarStyles[mode],
                    borderRadius: "16px",
                },
            },
            scrollbarStylesSoft: {
                "&::-webkit-scrollbar": {
                    width: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: palette.scrollbarStyles[mode],
                },
            },
            palette: {
                type: mode,
                mode: mode,
                primary: palette.primary[mode],
                secondary: palette.secondary[mode],
                textPrimary: palette.textPrimary[mode],
                dark: {
                    main: palette.dark[mode],
                },
                light: {
                    main: palette.light[mode],
                },
                tabHeaderBackground: palette.tabHeaderBackground[mode],
                optionsBackground: palette.optionsBackground[mode],
                fancyBackground: palette.fancyBackground[mode],
                total: palette.total[mode],
                messageIcons: palette.messageIcons[mode],
                inputBackground: palette.inputBackground[mode],
                barraSuperior: palette.barraSuperior[mode],
                topBarIconsColor: palette.topBarIconsColor[mode],
                topBarTextColor: palette.topBarTextColor[mode],
                barraSuperiorLogo: palette.barraSuperiorLogo[mode],
            },
            shape: {
                borderRadius: 10,
            },
            mode,
        },
        locale,
    )

    useEffect(() => {
        const i18nlocale = localStorage.getItem("i18nextLng")
        const browserLocale =
            i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5)

        if (browserLocale === "ptBR") {
            setLocale(ptBR)
        }
    }, [])

    useEffect(() => {
        window.localStorage.setItem("preferredTheme", mode)
    }, [mode])

    return (
        <ColorModeContext.Provider value={{ colorMode }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <QueryClientProvider client={queryClient}>
                    <Routes />
                </QueryClientProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}

export default App
