import React, {
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react"

import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material"

import { makeStyles } from "@mui/styles"

import MainContainer from "../../components/MainContainer"
import MainHeader from "../../components/MainHeader"
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper"
import TableRowSkeleton from "../../components/TableRowSkeleton"
import Title from "../../components/Title"
import { i18n } from "../../translate/i18n"
import toastError from "../../errors/toastError"
import api from "../../services/api"
import { DeleteOutline, Edit } from "@mui/icons-material"
import PromptModal from "../../components/PromptModal"
import { toast } from "react-toastify"
import ConfirmationModal from "../../components/ConfirmationModal"
import { socketConnection } from "../../services/socket"
import { io } from "socket.io-client"
import { SocketContext } from "../../context/Socket/SocketContext"

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}))

const reducer = (state, action) => {
    if (action.type === "LOAD_PROMPTS") {
        const prompts = action.payload
        const newPrompts = []

        prompts.forEach((prompt) => {
            const promptIndex = state.findIndex((p) => p.id === prompt.id)
            if (promptIndex !== -1) {
                state[promptIndex] = prompt
            } else {
                newPrompts.push(prompt)
            }
        })

        return [...state, ...newPrompts]
    }

    if (action.type === "UPDATE_PROMPTS") {
        const prompt = action.payload
        const promptIndex = state.findIndex((p) => p.id === prompt.id)

        if (promptIndex !== -1) {
            state[promptIndex] = prompt
            return [...state]
        } else {
            return [prompt, ...state]
        }
    }

    if (action.type === "DELETE_PROMPT") {
        const promptId = action.payload
        const promptIndex = state.findIndex((p) => p.id === promptId)
        if (promptIndex !== -1) {
            state.splice(promptIndex, 1)
        }
        return [...state]
    }

    if (action.type === "RESET") {
        return []
    }
}

const Prompts = () => {
    const classes = useStyles()

    const { socket } = useContext(SocketContext)

    const [prompts, dispatch] = useReducer(reducer, [])
    const [loading, setLoading] = useState(false)

    const [promptModalOpen, setPromptModalOpen] = useState(false)
    const [selectedPrompt, setSelectedPrompt] = useState(null)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            try {
                const { data } = await api.get("/prompt")
                dispatch({ type: "LOAD_PROMPTS", payload: data.prompts })

                setLoading(false)
            } catch (err) {
                toastError(err)
                setLoading(false)
            }
        })()
    }, [])

    useEffect(() => {
        if (socket) {
            socket.on("prompt", (data) => {
                if (data.action === "update" || data.action === "create") {
                    console.log("update ")
                    dispatch({ type: "UPDATE_PROMPTS", payload: data.prompt })
                }

                if (data.action === "delete") {
                    console.log("delete ")
                    dispatch({
                        type: "DELETE_PROMPT",
                        payload: data.intelligenceId,
                    })
                }
            })
        }
    }, [socket])

    const handleOpenPromptModal = () => {
        setPromptModalOpen(true)
        setSelectedPrompt(null)
    }

    const handleClosePromptModal = () => {
        setPromptModalOpen(false)
        setSelectedPrompt(null)
    }

    const handleEditPrompt = (prompt) => {
        setSelectedPrompt(prompt)
        setPromptModalOpen(true)
    }

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false)
        setSelectedPrompt(null)
    }

    const handleDeletePrompt = async (promptId) => {
        try {
            const { data } = await api.delete(`/prompt/${promptId}`)
            toast.info(i18n.t(data.message))
        } catch (err) {
            toastError(err)
        }
        setSelectedPrompt(null)
    }

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    selectedPrompt &&
                    `${i18n.t("prompts.confirmationModal.deleteTitle")} ${
                        selectedPrompt.name
                    }?`
                }
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handleDeletePrompt(selectedPrompt.id)}
            >
                {i18n.t("prompts.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <PromptModal
                open={promptModalOpen}
                onClose={handleClosePromptModal}
                promptId={selectedPrompt?.id}
            />
            <MainHeader>
                <Title>{i18n.t("prompts.title")}</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => socket.connect()}
                    >
                        connect
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenPromptModal}
                    >
                        {i18n.t("prompts.buttons.add")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                {i18n.t("prompts.table.name")}
                            </TableCell>
                            <TableCell align="left">
                                {i18n.t("prompts.table.queue")}
                            </TableCell>
                            <TableCell align="left">
                                {i18n.t("prompts.table.max_tokens")}
                            </TableCell>
                            <TableCell align="center">
                                {i18n.t("prompts.table.actions")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {prompts.map((prompt) => (
                                <TableRow key={prompt.id}>
                                    <TableCell align="left">
                                        {prompt.name}
                                    </TableCell>
                                    <TableCell align="left">
                                        {prompt.queue.name}
                                    </TableCell>
                                    <TableCell align="left">
                                        {prompt.maxTokens}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleEditPrompt(prompt)
                                            }
                                        >
                                            <Edit />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedPrompt(prompt)
                                                setConfirmModalOpen(true)
                                            }}
                                        >
                                            <DeleteOutline />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Prompts
