import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"

import ModalImage from "react-modal-image"
import axios from "axios"
import api from "../../services/api"

import CloseIcon from "@mui/icons-material/Close"
import Downloadcon from "@mui/icons-material/Download"

import { Box, Dialog, DialogTitle, IconButton } from "@mui/material"

const useStyles = makeStyles(() => ({
    messageMedia: {
        objectFit: "cover",
        width: 250,
        height: 200,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        cursor: "pointer",
    },
}))

const ModalImageCors = ({ imageUrl, body }) => {
    const classes = useStyles()
    const [fetching, setFetching] = useState(true)
    const [blobUrl, setBlobUrl] = useState("")

    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (!imageUrl) return
        let isMounted = true

        const fetchImage = async () => {
            try {
                const { data, headers } = await api.get(
                    `/proxy/getFirebaseFile?mediaUrl=${encodeURIComponent(imageUrl)}`,
                    { responseType: "blob" },
                )
                if (isMounted) {
                    const type = headers["content-type"]

                    const url = window.URL.createObjectURL(
                        new Blob([data], { type }),
                    )

                    setBlobUrl(url)
                    setFetching(false)
                }
            } catch (error) {
                console.error("Erro ao buscar a imagem:", error)
            }
        }
        fetchImage()

        return () => {
            isMounted = false
            if (blobUrl) {
                window.URL.revokeObjectURL(blobUrl)
            }
        }
    }, [imageUrl])

    const handleClose = () => {
        setOpen(false)
    }

    const handleDownload = () => {
        if (!blobUrl) return
        const link = document.createElement("a")
        link.href = blobUrl
        link.download = body
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <>
            <img
                src={fetching ? imageUrl : blobUrl}
                alt="image"
                className={classes.messageMedia}
                onClick={() => setOpen(true)}
            />

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                scroll="paper"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">{body}</DialogTitle>
                <Box
                    sx={(theme) => ({
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <IconButton aria-label="close" onClick={handleDownload}>
                        <Downloadcon color="primary" />
                    </IconButton>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <img src={fetching ? imageUrl : blobUrl} alt="image" />
            </Dialog>
        </>
    )
}

export default ModalImageCors
