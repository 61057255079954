import { io } from "socket.io-client"
import { isObject } from "lodash"

// export function socketConnection(params) {
//     try {
//         let userId = null
//         if (localStorage.getItem("userId")) {
//             userId = localStorage.getItem("userId")
//         }

//         const socket = io(process.env.REACT_APP_BACKEND_URL, {
//             transports: ["websocket", "polling", "flashsocket"],
//             pingTimeout: 18000,
//             pingInterval: 18000,
//             query: isObject(params) ? { ...params, userId } : { userId },
//         })

//         return socket
//     } catch (e) {
//         console.log(e)
//     }
// }

export default function socketConnection() {}
