import React, { StrictMode } from "react"
import ReactDOM from "react-dom/client"

import App from "./App"
import { SocketProvider } from "./context/Socket/SocketContext"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <StrictMode>
        <SocketProvider>
            <App />
        </SocketProvider>
    </StrictMode>,
)
