import React, { createContext, useEffect, useRef, useState } from "react"
import { io } from "socket.io-client"

const SocketContext = createContext()

const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState({
        connected: false,
        on: () => {},
        emit: () => {},
        onAny: () => {},
    })
    const socketRef = useRef(null)

    function getSocket() {
        let userId = localStorage.getItem("userId")
        let companyId = localStorage.getItem("companyId")

        const newSocket = io(process.env.REACT_APP_BACKEND_URL, {
            transports: ["websocket", "polling"],
            query: { companyId, userId },
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 3000,
        })

        setTimeout(() => {
            socketRef.current = newSocket
            setSocket(newSocket)

            newSocket.on("connect", () => console.log("🟢 CONNECTED"))
            newSocket.on("disconnect", (reason) =>
                console.log("🔴 DISCONNECT", reason),
            )
            newSocket.on("reconnect", (reason) =>
                console.log("♻️ RECONNECT", reason),
            )
            newSocket.on("error", (err) => console.log("⚠️ ERROR", err))
        }, 3000)
    }

    useEffect(() => {
        getSocket()
    }, [])

    return (
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    )
}

export { SocketContext, SocketProvider }
